<template>
  <svg
  class="chevron-down-icon"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
    <path
    d="M4 8L12 16L20 8"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"></path>
  </svg>
</template>

<script>
export default {
  name: 'CaretDownIcon'
}
</script>
