<template>
  <td
  v-if="customPli"
  class="p-0">
    <select
    class="p-2 focus:outline-focus shadow-remove"
    :class="this.$props.disabled ? 'bg-primary-light' : ''"
    :value="this.modelValue"
    @blur="$emit('blur')"
    @change="$emit('update:modelValue', $event.target.value)"
    :disabled="this.$props.disabled"
    name="unit">
      <option
      value=""
      selected>{{ DEFAULT_SELECT_STR }}</option>
      <option
      v-for="unit in this.units"
      :key="unit.id"
      :value="unit.id">{{ unit.name }}</option>
    </select>
  </td>
  <td
  v-else
  class="pl-2 py-2"
  :class="this.$props.disabled ? 'bg-primary-light' : ''">
    {{ this.$props.alt.unit.name }}
  </td>
</template>

<script>
import { DEFAULT_SELECT_STR } from '@/components/js/constants'
import { mapState } from 'vuex'

export default {
  name: 'UnitSelect',
  emits: ['update:modelValue'],
  props: ['customPli', 'modelValue', 'alt', 'disabled'],
  computed: {
    DEFAULT_SELECT_STR () { return DEFAULT_SELECT_STR },
    ...mapState({
      units: state => state.units
    })
  }
}
</script>
