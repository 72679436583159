<template>
    <label class="block">
        <span
          class="text-neutral-700"
          :class="this.$props.required ? ' font-bold' : ''">
          {{ labelStr }}</span>
        <slot></slot>
    </label>
</template>

<script>
export default {
  name: 'Label',
  props: {
    labelStr: String,
    required: Boolean
  }
}
</script>
