<template>
  <tr :class="this.$props.hideTitle ? '' : 'border-t-2 border-primary-light'">
    <td v-if="!this.$props.hideTitle" class="align-bottom pt-6  font-semibold text-text">
      Category subtotals:
    </td>
    <td class="align-bottom pt-6 "></td>
    <td
    class="align-bottom pt-6 "
    :class="this.$props.allRecsPaid ? 'text-green-500' : 'text-yellow-500'"
    v-if="this.$props.recMode">
      <span class="block text-sm font-normal text-text-metadata">Total invoiced:</span>
      <span class="font-semibold text-text font-mono">{{ penceToPounds(this.$props.totalRecInvoiced) }}</span>
    </td>
    <td
    v-for="n in 9"
    class="align-bottom pt-6 "
    :key="n"></td>
    <td class="align-bottom pt-6 px-2">
      <span class="block text-sm font-normal text-text-metadata">Break even:</span>
      <span class="font-semibold text-text font-mono">{{ penceToPounds(this.$props.totalBreakEvenCost) }}</span>
    </td>
    <td class="align-bottom pt-6 px-2">
      <span class="block text-sm font-normal text-text-metadata">Total billable:</span>
      <span class="font-semibold text-text font-mono">{{ penceToPounds(this.$props.totalBillable) }}</span>
    </td>
    <td class="align-bottom pt-6 px-2">
      <span class="block text-sm font-normal text-text-metadata">Margin:</span>
      <span class="font-semibold text-text font-mono">{{ penceToPounds(this.$props.margin) }}</span>
    </td>
    <td class="pt-6 "></td>
    <td class="pt-6 "></td>
  </tr>
</template>

<script>
import { penceToPounds } from '@/components/js/utils.js'
export default {
  name: 'category-totals',
  methods: {
    penceToPounds
  },
  props: [
    'totalRecInvoiced',
    'totalBreakEvenCost',
    'totalBillable',
    'margin',
    'recMode',
    'allRecsPaid',
    'hideTitle'
  ]
}
</script>
