<template>
  <Input
      :modelValue="formattedValue"
      @input="handleInput"
      @blur="handleBlur"
      type="text"
      :required="required"
      :disabled="disabled"
    />
</template>

<script>
import Input from '@/components/input.vue'
import { penceToPounds, poundsToPence } from '@/components/js/utils.js'

export default {
  name: 'MoneyInput',
  components: {
    Input
  },
  data () {
    return {
      // use pounds value for displaying
      formattedValue: penceToPounds(this.$props.modelValue, true)
    }
  },
  props: ['modelValue', 'required', 'disabled'],
  methods: {
    penceToPounds: penceToPounds,
    poundsToPence: poundsToPence,
    /**
     * emit modelValue update with pence value
     */
    handleInput (e) {
      this.formattedValue = e.target.value
      this.$emit('update:modelValue', poundsToPence(this.formattedValue))
    },
    /**
     * format the entered value to be pounds
     */
    handleBlur () {
      this.formattedValue = penceToPounds(poundsToPence(this.formattedValue), true)
    }
  },
  beforeUpdate () {
    if (!this.formattedValue) {
      this.formattedValue = penceToPounds(this.$props.modelValue, true)
    }
  }
}
</script>
