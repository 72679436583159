<template>
  <div class="flex flex-row items-center">
    <slot v-if="!editing"></slot>
    <button
    class="text-white hover:text-primary-lightest"
    @click="toggleEditing"
    type="button">
      <Icon
      v-if="!editing"
      class="ml-3 h-4 w-4 transition-colors stroke-current"
      name="edit"/>
    </button>
    <Input
      v-if="editing && !useTextarea"
      :placeholder="modifiedValue"
      :type="type || 'text'"
      @keyup.enter="handleSave"
      field=""
      inputclass="h-8 input--dark_background"
      required
      v-model="modifiedValue"
    />
    <textarea
      v-if="editing && useTextarea"
      :placeholder="modifiedValue"
      @keyup.enter="handleSave"
      class="h-32 w-full max-w-3xl input--dark_background"
      v-model="modifiedValue"
    ></textarea>

    <Button
      v-if="editing"
      class="transition-colors h-8"
      buttontype="border"
      size="small"
      @click="handleSave"
    >
      <Icon
        v-if="editing"
        class="stroke-current"
        name="tick"
      />
    </Button>
  </div>
</template>
<script>
import Icon from '@/components/icon.vue'
import Input from '@/components/input.vue'
import Button from '@/components/button.vue'

export default {
  name: 'EditableLabel',
  props: ['modelValue', 'type', 'useTextarea'],
  emits: ['update:modelValue'],
  components: {
    Icon,
    Input,
    Button
  },
  data () {
    return {
      editing: false,
      modifiedValue: null,
      errors: []
    }
  },
  created () {
    this.modifiedValue = this.$props.modelValue
  },
  methods: {
    toggleEditing: function (e) {
      e.preventDefault()
      if (!this.modifiedValue) {
        this.modifiedValue = this.$props.modelValue
      }
      this.editing = !this.editing
    },
    handleSave: function () {
      this.$emit('update:modelValue', this.modifiedValue)
      this.editing = false
    }
  },
  watch: {
    modelValue: function (value, prevValue) {
      if (value !== prevValue) {
        this.modifiedValue = value
      }
    }
  }
}
</script>
