import * as utils from '@/components/js/utils.js'
import * as constants from '@/components/js/constants.js'

const projectModule = {
  state: () => ({
    projects: [],
    project: {},
    projectClone: {},
    projectListFilters: {
      filterChoice: '',
      orderChoice: '-added_datetime',
      searchTerm: ''
    }
  }),
  mutations: {
    setProject (state, project) {
      state.project = project
    },
    setCount (state, count) {
      state.count = count
    },
    setProjects (state, projects) {
      state.projects = projects
    },
    updateProject (state, payload) {
      for (const [key, value] of Object.entries(payload)) {
        state.project[key] = value
      }
    },
    setProjectClone (state, projectClone) {
      state.projectClone = projectClone
    },
    setProjectListFilters (state, payload) {
      for (const [key, value] of Object.entries(payload)) {
        state.projectListFilters[key] = value
      }
    }
  },
  actions: {
    getProject ({ commit }, id) {
      return new Promise((resolve, reject) => {
        utils.apiRequest.get(`project/${id}/`).then(response => {
          commit('setProject', response.data)
          resolve()
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      })
    },
    getCount ({ commit }) {
      utils.apiRequest('project/count/').then(response => {
        commit('setCount', response.data)
      })
    },
    getProjects ({ state, commit }, noFilter) {
      const querystringItems = []

      if (!noFilter) {
        if (state.projectListFilters.filterChoice) {
          querystringItems.push(state.projectListFilters.filterChoice)
        }

        if (state.projectListFilters.orderChoice) {
          querystringItems.push(`ordering=${state.projectListFilters.orderChoice}`)
        }

        if (state.projectListFilters.searchTerm) {
          querystringItems.push(`search=${state.projectListFilters.searchTerm}`)
        }
      }

      return new Promise((resolve, reject) => {
        utils.getRequest('project', querystringItems.join('&'), function (response) {
          resolve(response)
          commit('setProjects', response.data)
        })
      })
    },
    setProjectClone ({ commit }, source) {
      const clone = {
        title: `${source.title} - Copy`,
        description: source.description,
        client: null,
        point_of_contact: source.point_of_contact,
        client_budget_pence: source.client_budget_pence,
        client_discount_pc: source.client_discount_pc,
        production_fee_pc: source.production_fee_pc,
        organisation: source.organisation
      }
      commit('setProjectClone', clone)
    },
    postCloneProject ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('setFormValidationErrors', [])

        // make request to clone project
        utils.apiRequest.post(`project/${payload.sourceId}/clone_project/`, payload.clone).then((res) => {
          resolve()
        }).catch((err) => {
          // set form validation errors if bad request
          if (err.response.status === constants.HTTP_400_BAD_REQUEST) {
            commit('setFormValidationErrors', err.response.data)

            if (err.response.data.non_field_errors[0] === 'max_projects exceeded') {
              commit('addAlert', {
                status: 'error',
                timer: 8000,
                message: constants.maxProjectsMessage
              })
            } else if ('non_field_errors' in err.response.data) {
              if (err.response.data.non_field_errors[0] === 'The fields client, title must make a unique set.') {
                commit(
                  'setFormValidationErrors',
                  { ...err.response.data, title: ['Title must be unique for each client'] }
                )
              } else {
                commit('addAlert', {
                  status: 'error',
                  message: err.response.data.non_field_errors[0],
                  timer: 4000
                })
              }
            }
          } else if (
            err.response.status !== constants.HTTP_400_BAD_REQUEST &&
            !('non_field_errors' in err.response.data)
          ) {
            // else use generic error alert
            commit('addAlert', {
              status: 'error',
              message: `Could not clone project (${payload.sourceId}), please try again.`,
              timer: 4000
            })
          }
          reject(err)
        })
      })
    },
    patchProject ({ state, commit, dispatch }) {
      // Construct the patch data to only include fields we want to update
      const patchData = constants.PROJECT_PATCH_FIELDS.reduce(
        function (subset, field) { subset[field] = state.project[field]; return subset }, {}
      )

      utils.apiRequest.patch(`project/${state.project.id}/`, patchData).then((res) => {
        commit('setProject', res.data)
      }).catch(err => {
        dispatch('getProject', state.project.id)
        commit('addAlert', {
          status: 'error',
          message: 'Could not update project, please try again.',
          timer: 3000
        })
        console.log(err)
      })
    }
  }
}

export default projectModule
