import * as utils from '@/components/js/utils.js'

const userModule = {
  state: () => ({
    user: {
      isAuthenticated: false,
      username: null,
      password: null
    },
    organisation: {
      name: null,
      max_projects: null
    }
  }),
  mutations: {
    clearUser (state) {
      state.user = {
        isAuthenticated: false,
        username: null,
        password: null
      }
    },
    clearOrganisation (state) {
      state.organisation = {
        name: null,
        max_projects: null
      }
    },
    setUser (state, user) {
      state.user = user
    },
    setOrgaisation (state, organisation) {
      state.organisation = organisation
    }
  },
  actions: {
    async getUser ({ state, commit }) {
      try {
        const response = await utils.apiRequest.get('identify/')
        const user = state.user
        user.username = response.data.username
        user.isAuthenticated = true
        commit('setUser', user)
      } catch (e) {
        console.log(e)
      }
    },
    async getOrganisation ({ _, commit }) {
      try {
        const response = await utils.apiRequest.get('organisation/')
        if (Array.isArray(response.data) && response.data.length === 1) {
          commit('setOrgaisation', response.data[0])
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  getters: {}
}

export default userModule
