const alertsModule = {
  state: () => ({
    alerts: []
  }),
  mutations: {
    addAlert (state, alert) {
      alert.id = state.alerts.length + 1
      state.alerts = [...state.alerts, alert]
    },
    setAlerts (state, alerts) {
      state.alerts = alerts
    }
  },
  actions: {
    DeleteAlert ({ state, commit }, id) {
      let alerts = [...state.alerts]
      alerts = alerts.filter(item => item.id !== id)
      commit('setAlerts', alerts)
    }
  }
}

export default alertsModule
