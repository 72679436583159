<template>
  <li class="w-full">
    <details class="border border-primary-light rounded bxsh px-3"
      :class="this.categoryUsed && !category.expanded ? 'category_used' : ''" :id="`category-${category.id}`"
      :open="category.expanded ? true : false">
      <summary
        class="flex flex-row items-center justify-between py-3 cursor-pointer text-primary hover:text-primary-dark"
        @click="toggleDetails" v-bind:id="`category-${category.id}-summary`">
        <div class="flex-none">
          <span class="text-gray-600 text-sm">Category:</span>
          <h3 class="mt-1 mb-0 text-xl text-gray-800">{{ category.name }}</h3>
        </div>
        <div class="flex flex-row flex-grow items-center justify-end">
          <category-totals v-if="!category.expanded" :hideTitle="true" :margin="margin" :recMode="this.project.rec_mode"
            :totalBillable="totalBillable" :totalBreakEvenCost="totalBreakEvenCost" :totalRecInvoiced="totalRecInvoiced"
            :allRecsPaid="allRecsPaid" />
          <div
            class="flex-none flex items-center justify-center ml-6 rounded-full h-8 w-8 border border-current transform transition-all cursor-pointer"
            :class="[category.expanded ? 'rotate-180' : '']">
            <TriangleDownIcon class="h-3 w-3 transition-colors fill-current" />
          </div>
        </div>
      </summary>
      <div class="overflow-x-scroll">
        <table class="table-auto w-full bg-white border-t-2 border-primary-light pt-6">
          <category-table-header :recMode="this.project.rec_mode" />
          <project-line-items :projectLineItems="categoryProjectLineItems" />
          <category-totals :margin="margin" :recMode="this.project.rec_mode" :totalBillable="totalBillable"
            :totalBreakEvenCost="totalBreakEvenCost" :totalRecInvoiced="totalRecInvoiced" :allRecsPaid="allRecsPaid" />
        </table>
        <!-- controls to add a new item -->
        <Button
          v-if="!this.project.archived"
          class="
            flex flex-row items-center mt-6 ml-0 mb-4
            transition-colors text-primary border-primary
            hover:text-primary-dark hover:border-primary-dark
          "
          buttontype="border"
          @click="addNewRow()"
          size="small"
        >
          <span class="block flex-auto mr-3">Add a new custom item</span>
          <Icon class="block flex-none h-6 w-6" name="plus" />
        </Button>
      </div>
    </details>
  </li>
</template>

<script>
import * as utils from '@/components/js/utils'
import Button from '@/components/button.vue'
import CategoryTableHeader from './categoryTableHeader.vue'
import CategoryTotals from './categoryTotals.vue'
import Icon from '@/components/icon.vue'
import ProjectLineItems from './projectLineItems.vue'
import TriangleDownIcon from '@/components/svg/iconTriangleDown.vue'
import { mapState } from 'vuex'

export default {
  name: 'Category',
  components: {
    Button,
    CategoryTableHeader,
    CategoryTotals,
    Icon,
    ProjectLineItems,
    TriangleDownIcon
  },
  props: {
    category: Object
  },
  methods: {
    penceToPounds: utils.penceToPounds,
    toggleDetails: function (e) {
      e.preventDefault()
      this.$store.commit('setCategoryExpanded', {
        categoryId: this.category.id,
        expanded: !this.category.expanded
      })
    },
    addNewRow () {
      const data = {
        category: this.category.id,
        project: this.project.id
      }

      if (this.recMode) {
        data.unit = 2
        data.quantity = 100
      }

      this.$store.dispatch('createProjectLineItem', data)
    }
  },
  computed: {
    ...mapState({
      project: state => state.projects.project,
      archived: state => !state.projects.project.is_active,
      recMode: state => state.projects.project.rec_mode
    }),
    categoryProjectLineItems: function () {
      return utils.filterProjectLineItemsByCategory(
        this.$store.state.projectLineItems.projectLineItems,
        this.$props.category.id
      )
    },
    categoryUsed: function () {
      let used = false
      this.categoryProjectLineItems.forEach(li => {
        if (
          li.prep_time_days ||
          li.work_time_days ||
          li.quantity
        ) {
          used = true
        }
      })
      return used
    },
    totalBreakEvenCost: function () {
      let total = 0
      this.categoryProjectLineItems.forEach(pli => {
        total += utils.breakEvenCost(pli) || 0
      })
      return total
    },
    totalBillable: function () {
      let total = 0
      this.categoryProjectLineItems.forEach(pli => {
        total += utils.totalBillable(pli)
      })
      return total
    },
    margin: function () {
      return this.totalBillable - this.totalBreakEvenCost
    },
    totalRecInvoiced: function () {
      let total = 0
      this.categoryProjectLineItems.forEach(pli => {
        total += utils.recInvoiced(pli)
      })
      return total
    },
    allRecsPaid: function () {
      let allPaid = true
      this.categoryProjectLineItems.forEach(pli => {
        if (!utils.recPaid(pli)) {
          allPaid = false
        } else if (!pli.rec) {
          allPaid = false
        }
      })
      return allPaid
    }
  }
}
</script>
