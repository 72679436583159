<template>
  <button
  class="rounded m-2 px-2 py-1 border"
  :class="buttonClass"
  type="button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: ['buttontype', 'size'],
  data () {
    let styles
    switch (this.$props.buttontype) {
      case 'background':
        styles = 'bg-primary hover:bg-primary-light text-white border-opacity-0'
        break
      case 'border':
        styles = 'border-primary hover:border-primary-bright text-primary hover:text-primary-bright'
        break
      case 'neutral-border':
        styles = 'border-neutral-500 hover:border-neutral-600 hover:text-neutral-800'
        break
      case 'disabled-border':
        styles = 'border-neutral-300 text-neutral-300'
        break
      default:
        styles = 'border-opacity-0 hover:text-neutral-800'
        break
    }
    switch (this.$props.size) {
      case 'large':
        styles = `${styles} text-lg`
        break
      case 'small':
        styles = `${styles} text-sm`
        break
      default:
        break
    }
    return {
      buttonClass: [styles]
    }
  }
}
</script>
