<template>
  <div
  v-if="recModal.visible"
  class="fixed w-screen h-screen flex flex-col z-50 bg-neutral-500 bg-opacity-75">
    <!-- Modal Header -->
    <div class="m-auto min-w-max w-96 bg-neutral-100 rounded-lg">
      <div class="p-4 border-b-2 flex flex-row items-center justify-between">
        <h2 class="mr-10 ml-4">{{ recModal.title }}</h2>
        <a
        @click.prevent="close"
        href="#">
          <Icon
          class="h-4 w-4"
          name="close"/>
        </a>
      </div>
      <!-- End Modal Header -->
      <!-- Rec Modal Body -->
      <div class="p-8 text-neutral-600 flex flex-col">
        <label for="invoice_no">Invoice No.</label>
        <input
        class="rounded-md"
        :class="recModal.editable ? '' : 'bg-neutral-100'"
        id="invoice_no"
        type="text"
        v-model="recModal.rec.invoice_no"
        :disabled="!recModal.editable">
        <label for="invoice_amount">Invoice Amount</label>
        <MoneyInput
        class="rounded-md"
        :disabled="!recModal.editable"
        id="invoice_amount"
        v-model="recModal.rec.invoice_amount"/>
        <div v-if="recModal.rec.errors">{{ errors }}</div>
        <label for="notes">Notes</label>
        <textarea
        class="rounded-md"
        :class="recModal.editable ? '' : 'bg-neutral-100'"
        :disabled="!recModal.editable"
        cols="30"
        id="notes"
        rows="10"
        v-model="recModal.rec.notes">
        </textarea>
        <div class="flex flex-col text-lg mt-2">
          <div>
            <input
              class="mx-2 h-4 w-4"
              :class="recModal.editable ? '' : 'bg-neutral-100'"
              type="checkbox"
              id="invoice_received"
              v-model="recModal.rec.invoice_received"
              :disabled="!recModal.editable">
            <label for="invoice_received">Invoice received</label>
          </div>
          <div class="mb-4">
            <input
              class="mx-2 h-4 w-4"
              :class="recModal.editable ? '' : 'bg-neutral-100'"
              type="checkbox"
              id="paid"
              v-model="recModal.rec.paid"
              :disabled="!recModal.editable">
            <label for="paid">Invoice paid</label>
          </div>
        </div>
        <div class="flex flex-row items-center">
          <Button
          class="w-1/2"
          :buttontype="recModal.editable ? 'background' : 'neutral-border'"
          :disabled="!recModal.editable"
          @click.prevent="onClick">Save</Button>
          <Button
          class="w-1/2"
          @click.prevent="close"
          buttontype="border">Cancel</Button>
        </div>
      </div>
      <!-- End Rec Modal Body -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Icon from '@/components/icon.vue'
import Button from '@/components/button.vue'
import MoneyInput from '@/components/moneyInput.vue'
export default {
  name: 'recModal',
  components: { Icon, Button, MoneyInput },
  computed: mapState({ recModal: state => state.modalModule.recModal }),
  methods: {
    close () { this.recModal.visible = false },
    open () { this.recModal.visible = true },
    onClick () {
      this.recModal.onClick()
      this.recModal.visible = false
    }
  }
}
</script>
