<template>
  <a
    href="#"
    class="underline"
    :class="this.class()"
    @click.prevent="open"
  >
    <CalcIcon class="h-5 w-5 transition-colors stroke-current"/>
  </a>
</template>
<script>

import { penceToPounds } from '@/components/js/utils.js'
import CalcIcon from '@/components/svg/iconCalc.vue'

const DEFAULT_REC = {
  invoice_no: null,
  invoice_amount: null,
  notes: '',
  invoice_received: false,
  paid: false
}

export default {
  name: 'recModalLink',
  components: {
    CalcIcon
  },
  methods: {
    class () {
      if (
        this.$props.pli.rec &&
        this.$props.pli.rec.invoice_amount !== null &&
        this.$props.pli.rec.invoice_amount !== undefined
      ) {
        if (this.$props.pli.rec.paid) {
          return 'text-green-500 hover:text-green-600'
        }
        return 'text-yellow-500 hover:text-yellow-600'
      }
      return ''
    },
    open () {
      const rec = this.$props.pli.rec || DEFAULT_REC

      this.$store.commit('setRecModal', {
        title: this.$props.name,
        editable: !this.$props.disabled,
        visible: true,
        errors: {},
        rec: JSON.parse(JSON.stringify(rec)),
        onClick: function () {
          // update pli.rec with data entered in modal
          this.$props.pli.rec = this.$store.state.modalModule.recModal.rec

          // set this as new recs pli.id if it doesn't already have one
          if (!this.$props.pli.rec.id) {
            this.$props.pli.rec.project_line_item = this.$props.pli.id
          }

          // validation
          if (
            isNaN(this.$props.pli.rec.invoice_amount) ||
            this.$props.pli.rec.invoice_amount === 0 ||
            this.$props.pli.rec.invoice_amount < 0
          ) {
            this.$store.commit('setRecModal', {
              ...rec, errors: ['Invoice Amount is invalid.']
            })
          }

          // patch rec to backend
          this.$store.dispatch('patchRec', {
            id: this.$props.pli.rec.id || null,
            rec: this.$props.pli.rec
          })
        }.bind(this)
      })
    },
    linkText () {
      if (
        this.$props.pli.rec &&
        this.$props.pli.rec.invoice_amount !== null &&
        this.$props.pli.rec.invoice_amount !== undefined
      ) {
        return penceToPounds(this.$props.pli.rec.invoice_amount)
      }
      return 'Rec'
    }
  },
  props: ['pli', 'name', 'disabled']
}
</script>
