<script setup>
import { defineProps, onMounted } from 'vue'
import { useStore } from 'vuex'
import Icon from '@/components/icon.vue'

const props = defineProps(['alert'])
const store = useStore()

onMounted(() => {
  if (props.alert.timer) {
    setTimeout(() => {
      store.dispatch('DeleteAlert', props.alert.id)
    }, props.alert.timer)
  }
})

function classObject (status) {
  switch (status) {
    case 'success':
      return 'bg-success-400 border-success-500 shadow-md'
    case 'warning':
      return 'bg-warning-400 border-warning-500 shadow-md'
    case 'error':
      return 'bg-error-400 border-error-500 shadow-md'
    default:
      return 'bg-neutral-200 border-neutral-300 shadow-md'
  }
}

function handleClose (key) {
  store.dispatch('DeleteAlert', key)
}

</script>

<template>
  <div
    v-if="alert"
    class="
      z-50 m-1 border-2 rounded-lg py-1 px-2
      text-lg flex justify-between items-center
    "
    :class="classObject(props.alert.status)"
  >
    <div>
      {{ props.alert.message }}
    </div>
    <div class="pl-2">
      <Icon
        name="close"
        class="h-4 w-4"
        @click="handleClose(props.alert.id)"
      />
    </div>
  </div>
</template>
