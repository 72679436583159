<template>
  <section
    class="container mt-6 rounded-md mx-auto bg-text-metadata p-3 text-white"
    id="project_totals"
  >
    <h2 class="text-2xl text-white tsh">Budget totals:</h2>
    <table class="w-2/3 table-fixed text-left">
      <tr
        v-for="field in totals"
        class="has-tooltip"
        :class="{ 'border-t-2 first:border-t-0 border-interface-divider' : field.name, 'hidden' : !field.name }"
        :key="field.name"
      >
        <th
          class="py-2 pr-3 w-1/6"
        >
          <span
            class="inline-block tsh"
            :class="{ 'text-yellow-500': field.rec }">
            <span :title="field.tooltip"><Icon name="info" class="inline-flex" /></span>
              {{ field.name }}:
          </span>
        </th>

        <td class="pr-3 w-1/6 text-right">
          <span
            class="inline-block font-mono"
            :class="(field.bold ? 'font-semibold ' : '') + (field.rec ? 'text-yellow-500 ' : '') + field.valueStyles"
          >
            {{ penceToPounds(field.value) }}
          </span>
        </td>

        <td class="pr-3 w-1/6 text-right">

          <EditableLabel
            v-if="field.percent !== undefined && field.editableKey && !this.project.rec_mode"
              class="font-mono float-right"
              :modelValue="field.percent"
              @update:modelValue="updateProject(field.editableKey, $event)"
              type="number"
            >
            <span
              class="inline-block align-right tsh"
              :class="{ 'font-semibold': field.bold, 'text-yellow-500': field.rec }"
            >
              {{ field.percent }}%
            </span>
          </EditableLabel>

          <EditableLabel v-else-if="field.percent === undefined && field.editableKey && !this.project.rec_mode"
            class="font-mono float-right"
            :modelValue="field.value / 100"
            @update:modelValue="updateProject(field.editableKey, $event)"
            type="number"
          ></EditableLabel>

          <span
            v-if="field.percent !== undefined && (!field.editableKey || this.project.rec_mode)"
            class="inline-block tsh mr-8"
            :class="{ 'font-semibold': field.bold, 'text-yellow-500': field.rec }"
          >
            {{ field.percent }}%
          </span>
        </td>

      </tr>
    </table>
  </section>
</template>

<script>
import { penceToPounds } from '@/components/js/utils.js'
import EditableLabel from '@/components/editableLabel.vue'
import Icon from '@/components/icon.vue'
import { mapState } from 'vuex'

export default {
  name: 'projectTotals',
  props: ['projectId'],
  components: { EditableLabel, Icon },
  methods: {
    penceToPounds: penceToPounds,
    updateProject (field, value) {
      // Update project in store if value exists
      if (value) {
        const proj = {}

        if (
          field === 'production_fee_pc' ||
          field === 'client_discount_pc'
        ) {
          if (
            value > 100 || value < 0 ||
            isNaN(value) || !Number.isInteger(Number(value))
          ) {
            this.$store.commit('addAlert', {
              status: 'warning',
              message: 'Could not set percentage, please enter a valid number.',
              timer: 3000
            })
          } else {
            proj[field] = value
          }
        } else if (field === 'client_budget_pence') {
          proj[field] = value * 100
        } else {
          proj[field] = value
        }
        this.$store.commit('updateProject', proj)
        this.$store.dispatch('patchProject')
        this.$store.dispatch('calculateAllTotals')
      }
    }
  },
  computed: {
    ...mapState({
      project: state => state.projects.project
    }),
    totals: function () {
      const totals = this.$store.getters.projectTotals
      if (totals) {
        return [
          {
            name: 'Costs',
            value: this.$store.getters.projectTotals.totalExternalCosts + this.$store.getters.projectTotals.totalInternalBECost,
            tooltip: 'The total of all billable amounts'
          },
          {
            name: 'Mark-up',
            percent: this.project.production_fee_pc,
            editableKey: 'production_fee_pc',
            value: this.$store.getters.projectTotals.productionFee,
            tooltip: 'External Costs multiplied by the production fee - This is your mark-up on external costs '
          },
          {
            name: 'Total estimated invoice',
            value: this.$store.getters.projectTotals.totalBillToClient,
            tooltip: 'Sum of all billable costs and the Mark-up',
            bold: true
          },
          {
            name: 'Invoice after discount',
            percent: this.project.client_discount_pc,
            editableKey: 'client_discount_pc',
            value: this.$store.getters.projectTotals.totalClientDiscount,
            tooltip: 'New total estimated invoice after the discount is applied'
          },
          {
            name: 'Estimated margin',
            percent: this.$store.getters.projectTotals.estimatedProfit ? this.$store.getters.projectTotals.estimatedProfit.percentage : null,
            value: this.$store.getters.projectTotals.estimatedProfit ? this.$store.getters.projectTotals.estimatedProfit.value : null,
            tooltip: 'Production fee plus mark ups on individual items',
            bold: true
          },
          {
            name: 'Client\'s available budget',
            value: this.project.client_budget_pence,
            tooltip: 'Clients agreed budget for the project',
            editableKey: 'client_budget_pence'
          },
          {
            name: 'Difference/achievability',
            value: this.$store.getters.projectTotals.difference,
            tooltip: 'Clients available budget minus the Total Invoice amount (inc. any discounts), must be green for the project to be viable',
            bold: true,
            valueStyles: this.$store.getters.projectTotals.difference >= 0 ? 'project_totals--has_profit' : 'project_totals--has_loss'
          },
          this.project.rec_mode ? {
            name: 'Bills received',
            value: this.$store.getters.projectTotals.totalRecInvoiced,
            tooltip: 'Sum of all recorded \'Invoice amounts\'',
            bold: true,
            rec: true,
            percent: Number((this.$store.getters.projectTotals.totalRecInvoiced / this.project.client_budget_pence) * 100).toFixed(2) || 0.00
          } : {},
          this.project.rec_mode ? {
            name: 'Margin',
            value: this.project.client_budget_pence - this.$store.state.totals.projectTotals.totalRecInvoiced,
            tooltip: 'Amount we\'ve charged the client, minus the \'Actual costs\' to us i.e. Margin',
            bold: true,
            rec: true,
            percent: Number(((this.project.client_budget_pence - this.$store.state.totals.projectTotals.totalRecInvoiced) / this.project.client_budget_pence) * 100).toFixed(2) || 0.00
          } : {}
        ]
      }
      return null
    }
  }
}
</script>
