<template>
  <div class="2xl:container mx-auto px-3 py-6">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'Page',
  props: ['title']
}
</script>
