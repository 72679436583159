<template>
  <thead class="text-sm font-semibold pt-8 mt-6 border-l-4 border-white h-16 align-top">
    <tr>
      <th class="pt-6 w-64 min-w-250px">Line Item:</th>
      <th class="pt-6 w-16 min-w-50px">Notes:</th>
      <th v-if="this.$props.recMode" class="pt-6">Actuals:</th>
      <th class="pt-6 w-16 min-w-50px">Days/Units:</th>
      <th class="pt-6 px-2">Quantity:</th>
      <th class="pt-6">Default rate:</th>
      <th class="pt-6">Rate to us:</th>
      <th class="pt-6">Billable rate:</th>
      <th class="pt-6 min-w-70px"><span class="block">Prep time</span><span class="font-normal text-text-metadata">(days)</span>:</th>
      <th class="pt-6 w-32 min-w-70px"><span class="block">Work time</span><span class="font-normal text-text-metadata">(days)</span>:</th>
      <th class="pt-6 min-w-70px"><span class="block">Overtime</span><span class="font-normal text-text-metadata">(hours)</span>:</th>
      <th class="pt-6 min-w-70px">Total cost to us:</th> <!-- break even -->
      <th class="pt-6 min-w-70px">Total billable:</th>
      <th class="pt-6 min-w-70px">Margin:</th>
      <th class="pt-6 w-16 min-w-50px">Mark Up:</th>
      <!-- <th class="table-figure-tens  "><span class="block">Night OT</span><span class="font-normal text-text-metadata">(hours)</span>:</th> -->
      <!-- column for controls for individual projectlineitem -->
      <th class="pt-6">Delete</th>
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'categoryTableHeader',
  props: ['recMode']
}
</script>
