const modalModule = {
  state: () => ({
    modal: {
      title: '',
      bodyText: '',
      visible: false,
      editable: false,
      onClick: () => {
        return null
      },
      type: '',
      rec: {}
    },
    recModal: {
      title: '',
      visible: false,
      editable: false,
      errors: {},
      onClick: () => {
        return null
      },
      onClose: () => {
        return null
      }
    }
  }),
  mutations: {
    setModal (state, modal) { state.modal = modal },
    setRecModal (state, recModal) { state.recModal = recModal }
  }
}

export default modalModule
