<template>
  <header aria-label="Project header">
    <div class="flex flex-row items-stretch justify-between">
      <div class="flex flex-row items-stretch justify-between flex-auto rounded-md bg-text-metadata p-3">
        <div class="flex-auto">
          <div class="flex flex-row items-start justify-between">
            <div class="flex-auto">
              <span class="block text-white text-sm tsh">Project title:</span>

              <h1
                v-if="disableEdit"
                class="project_header__project_title"
                id="title">
                {{ project.title }}
              </h1>

              <EditableLabel
                v-else
                :modelValue="project.title"
                @update:modelValue="updateProject('title', $event)">
                  <h1
                    class="project_header__project_title"
                    id="title">
                    {{ project.title }}
                  </h1>
              </EditableLabel>

              <p v-if="disableEdit" class="text-white font-bold max-w-2xl" id="description">{{ project.description }}</p>
              <EditableLabel
                  v-else
                  :modelValue="project.description"
                  :useTextarea="true"
                  @update:modelValue="updateProject('description', $event)"
              >
                <p class="text-white font-bold max-w-2xl" id="description">{{ project.description }}</p>
                </EditableLabel>
            </div>
            <div class="flex flex-row items-center justify-between">
              <DropdownSettings
              dropdowntype="options"
              v-bind:dropdownItems="projectOptions"
              @update:modelValue="event => selectOption(event)"></DropdownSettings>
            </div>
          </div>
          <ul class="flex flex-row items-end justify-start mt-6">
            <li class="flex-none">
              <span class="block text-white text-sm tsh">Client:</span>
              <router-link
              class="block text-xl font-semibold tsh text-white hover:text-primary-lightest transition-colors"
              to="">
                {{ project.client }}
              </router-link>
            </li>
            <li class="flex-none ml-8 mr-8">
              <span class="block text-white text-sm tsh">Client's total budget:</span>
              <span
                v-if="disableEdit"
                  id="client_budget_pence"
                  class="block text-xl font-mono font-semibold text-white tsh">
                   {{ penceToPounds(project.client_budget_pence) }}
              </span>
              <EditableLabel
                v-else
                id="client_budget_pence"
                class="text-xl font-mono font-semibold text-white tsh"
                type="text"
                :modelValue="penceToPounds(project.client_budget_pence, false)"
                @update:modelValue="updateProject('client_budget_pence', $event)">
                  {{ penceToPounds(project.client_budget_pence) }}
              </EditableLabel>
            </li>
            <li class="flex-none ml-auto">
              <span class="block text-white text-sm tsh">Project mode:</span>
              <span class="block text-sm font-semibold text-white tsh">
                <span v-if="!project.is_active">Archived</span>
                <span v-else-if="disableEdit">Live tracking</span>
                <span v-else>Estimation</span>
              </span>
            </li>
          </ul>
        </div>
        <address class="project_header__point_of_contact">
          <ul class="h-full">
            <li class="h-full flex flex-col items-start justify-between">
              <div>
                <span class="text-white text-sm tsh">Point of contact:</span>
                <span
                v-if="disableEdit"
                class="text-white font-semibold text-xl tsh">{{ project.point_of_contact }}</span>
                <EditableLabel
                v-else
                class="text-white font-semibold text-xl tsh"
                :modelValue="project.point_of_contact"
                @update:modelValue="updateProject('point_of_contact', $event)">
                  {{ project.point_of_contact }}
                </EditableLabel>
              </div>
              <Button @click="onSwitchMode" buttontype="background">
                {{!this.project.rec_mode ? 'Switch to live tracking' : 'Switch to estimate mode'}}
              </Button>
            </li>
          </ul>
        </address>
      </div>
      <div class="rounded-md bg-primary-dark p-4 ml-6">
        <ul :class="headerTotals ? 'visible text-right' : 'invisible text-right'">
          <li v-if="!this.project.rec_mode">
            <span class="block text-sm text-white tsh">Estimated bill to client:</span>
            <span class="block text-xl font-mono font-semibold text-white tsh">{{ headerTotals ? headerTotals.billToClient : 0 }}</span>
          </li>
          <li class="mt-2">
            <span class="block text-sm text-white tsh">Client's available budget:</span>
            <span class="block text-xl font-mono font-semibold text-white tsh">{{ headerTotals ? headerTotals.clientBudget : 0 }}</span>
          </li>
        </ul>
        <a
        class="inline-block mt-2 text-white hover:text-white text-sm tsh py-2 px-3 border-white border rounded"
        href="#project_totals">View summaries & totals</a>
      </div>
    </div>
    <div class="flex flex-row justify-end py-3 text-primary">
      <button
      class="rounded px-3 py-2 border border-primary bg-white transition-colors hover:border-primary-dark hover:text-primary-dark"
      @click="toggleDetails"
      type="button">
        <span
        v-if="$store.getters.projectDetailExpanded == true"
        class="flex flex-row items-center w-full">
          <span>Collapse all</span>
          <span class="ml-2"><CollapseIcon class="transition-colors h-4 w-4 stroke-current"/></span>
        </span>
        <span
        v-else
        class="flex flex-row items-center w-full">
          <span>Expand all</span>
          <span class="ml-2"><ExpandIcon class="transition-colors h-4 w-4 stroke-current"/></span>
        </span>
      </button>
    </div>
  </header>
</template>

<script>
import * as utils from '@/components/js/utils'
import CollapseIcon from '@/components/svg/iconCollapse.vue'
import DropdownSettings from '@/components/dropdownSettings.vue'
import EditableLabel from '@/components/editableLabel.vue'
import ExpandIcon from '@/components/svg/iconExpand.vue'
import Button from '@/components/button.vue'
import {
  defaultIfNone,
  penceToPounds,
  poundsToPence,
  budgetSummaryDocumentDefinition,
  pdfMakeTableLayouts
} from '@/components/js/utils.js'
import { mapState } from 'vuex'

var pdfMake = require('pdfmake/build/pdfmake.js')
var pdfFonts = require('pdfmake/build/vfs_fonts.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  SourceSerifPro: {
    normal: 'SourceSerifPro-Regular.ttf',
    bold: 'SourceSerifPro-Bold.ttf',
    italics: 'SourceSerifPro-Italic.ttf'
  },
  SourceSerifProLight: {
    normal: 'SourceSerifPro-Light.ttf',
    italics: 'SourceSerifPro-LightItalic.ttf',
    bold: 'SourceSerifPro-SemiBold.ttf'
  },
  SourceSansPro: {
    normal: 'SourceSansPro-Regular.ttf',
    bold: 'SourceSansPro-Bold.ttf',
    italics: 'SourceSansPro-Italic.ttf'
  },
  SourceSansProLight: {
    normal: 'SourceSansPro-Light.ttf'
  }
}

export default {
  name: 'ProjectHeader',
  components: {
    CollapseIcon,
    DropdownSettings,
    EditableLabel,
    ExpandIcon,
    Button
  },
  data () {
    return {
      selectedOption: null
    }
  },
  computed: {
    isFreeTier: function () {
      return this.max_projects === 1
    },
    projectOptions: function () {
      const options = [
        {
          displayStr: `Duplicate${this.isFreeTier ? ' (Pro only)' : ''}`,
          returnVal: '',
          route: '/clone-project/' + this.$props.project.id,
          disabled: this.isFreeTier
        },
        {
          displayStr: this.$props.project.is_active ? `Archive${this.isFreeTier ? ' (Pro only)' : ''}` : `Unarchive${this.isFreeTier ? ' (Pro only)' : ''}`,
          returnVal: '',
          disabled: this.isFreeTier
        },
        {
          displayStr: `Print budget summary${this.isFreeTier ? ' (Pro only)' : ''}`,
          returnVal: '',
          disabled: this.isFreeTier
        },
        {
          displayStr: `Print budget summary (all items)${this.isFreeTier ? ' (Pro only)' : ''}`,
          returnVal: '',
          disabled: this.isFreeTier
        }
      ]

      if (!this.archived) {
        options.splice(2, 0, {
          displayStr: `${this.$props.project.rec_mode ? 'Back to estimate' : 'Switch to live tracking'}`,
          returnVal: ''
        })
      }

      return options
    },
    expandedAll: function () {
      return this.$store.getters.projectDetailExpanded
    },
    ...mapState({
      disableEdit: state => state.projects.project.rec_mode || !state.projects.project.is_active,
      archived: state => !state.projects.project.is_active,
      projectTotals: state => state.totals.projectTotals,
      max_projects: state => state.user.organisation.max_projects
    }),
    headerTotals: function () {
      // show totals if user is on project detail page
      if (this.$route.name === 'Project Detail') {
        return {
          billToClient: utils.penceToPounds(this.projectTotals.totalBillToClient),
          clientBudget: utils.penceToPounds(this.project.client_budget_pence),
          leftToPay: utils.penceToPounds(this.project.client_budget_pence - this.projectTotals.totalRecInvoiced)
        }
      } else {
        return null
      }
    }
  },
  methods: {
    defaultIfNone,
    penceToPounds,
    toggleDetails: function () {
      this.$store.dispatch(
        'expandCollapseAllDepartmentsAndCategories',
        !this.$store.getters.projectDetailExpanded
      )
    },
    onSwitchMode () {
      this.$store.commit('setModal', {
        title: `Switch to ${this.project.rec_mode ? 'estimate' : 'live tracking'} mode?`,
        bodyText: '',
        type: 'confirmation',
        visible: true,
        onClick: () => {
          this.$store.commit(
            'updateProject',
            { rec_mode: !this.project.rec_mode }
          )
          this.$store.dispatch('patchProject')
          this.$store.dispatch(
            'getProjectLineItems',
            this.$store.state.projects.project
          )
          return null
        }
      })
    },
    updateProject (field, value) {
      // Update project in store with new values
      if (value) {
        const proj = {}
        if (field === 'client_budget_pence') {
          value = value.replace(/,/g, '') // remove commas
          if (!poundsToPence(value)) {
            this.$store.commit('addAlert', {
              status: 'warning',
              message: 'Could not set budget, please enter a valid number.',
              timer: 3000
            })
          } else {
            proj[field] = poundsToPence(value)
          }
        } else {
          proj[field] = value
        }
        this.$store.commit('updateProject', proj)
        this.$store.dispatch('patchProject')
        // recalculate totals
        this.$store.dispatch('calculateAllTotals')
      }
    },
    selectOption (value) {
      // enable Rec Mode / Est Mode
      if (
        value.displayStr === 'Back to estimate' ||
        value.displayStr === 'Switch to live tracking'
      ) {
        this.$store.commit('setModal', {
          title: `${value.displayStr === 'Back to estimate' ? 'Back to estimate' : 'Switch to live tracking'} mode?`,
          bodyText: '',
          type: 'confirmation',
          visible: true,
          onClick: () => {
            this.$store.commit(
              'updateProject',
              { rec_mode: value.displayStr === 'Switch to live tracking' }
            )
            this.$store.dispatch('patchProject')
            this.$store.dispatch(
              'getProjectLineItems',
              this.$store.state.projects.project
            )
            return null
          }
        })
      } else if (value.displayStr === 'Archive') {
        this.$store.commit('updateProject', { is_active: false })
        this.$store.dispatch('patchProject')
        this.$store.commit('addAlert', {
          status: 'success',
          message: `${this.$props.project.title} has been archived.`,
          timer: 3000
        })
        this.$router.push('/')
        return null
      } else if (value.displayStr === 'Unarchive') {
        this.$store.commit('updateProject', { is_active: true })
        this.$store.dispatch('patchProject')
        this.$store.commit('addAlert', {
          status: 'success',
          message: `${this.$props.project.title} has been unarchived.`,
          timer: 3000
        })
        return null
      } else if (value.displayStr === 'Print budget summary' && this.max_projects !== 1) {
        const docDefinition =
          budgetSummaryDocumentDefinition(
            this.$props.project,
            this.$store.getters.projectLineItemsByDepartment,
            this.$store.state.totals.projectTotals
          )
        pdfMake.createPdf(
          docDefinition,
          pdfMakeTableLayouts
        ).download(`${this.$props.project.title}-budget-summary.pdf`.replaceAll(' ', '-'))
        return null
      } else if (value.displayStr === 'Print budget summary (all items)' && this.max_projects !== 1) {
        const docDefinition =
          budgetSummaryDocumentDefinition(
            this.$props.project,
            this.$store.getters.projectLineItemsByDepartment,
            this.$store.state.totals.projectTotals,
            true
          )
        pdfMake.createPdf(
          docDefinition,
          pdfMakeTableLayouts
        ).download(`${this.$props.project.title}-budget-summary.pdf`.replaceAll(' ', '-'))
        return null
      }
    }
  },
  props: {
    project: Object
  }
}
</script>
