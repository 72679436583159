<template>
    <li
    v-if="departmentVisible"
    class="overflow-hidden w-full rounded-lg border-2 border-b-4 border-primary-light shadow-md bg-white"
    :class="department.expanded ? 'shadow-lg border-l-4 border-r-4' : ''"
    :id="`department-${department.id}`">
      <header
      class="flex flex-row items-center justify-between w-full cursor-pointer p-3 text-primary hover:text-primary-dark"
      :id="`department-${department.id}-summary`"
      @click="toggleDetails">
        <div class="flex-none">
          <span class="block text-text-metadata text-sm">Department:</span>
          <h2 class="mt-1 mb-0 text-2xl text-text">{{ department.name }}</h2>
        </div>
        <ul
        v-if="!department.expanded"
        class="flex-auto flex flex-row items-center justify-end ml-6 space-x-6">
          <li
          v-for="(total, idx) in totals"
          class="flex-none"
          :class="total.style"
          :key="idx">
            <span class="block text-sm text-text-metadata">{{ total.name }}:</span>
            <span class="totals-figure block text-xl font-mono font-semibold text-text">{{ penceToPounds(total.value) }}</span>
          </li>
        </ul>
        <Button
        v-if="department.expanded"
        class="mt-0 mr-0 mb-0 ml-auto py-2 px-4 transition-colors hover:text-primary-dark hover:border-primary-dark"
        @click.stop="toggleCategories"
        buttontype="border"
        size="small">Expand/Collapse all Categories</Button>
        <div
        class="flex-none flex items-center justify-center ml-6 rounded-full h-10 w-10 border border-current transition-all transform"
        :class="[department.expanded ? 'rotate-180' : '']">
          <TriangleDownIcon class="h-4 w-4 transition-colors fill-current"/>
        </div>
      </header>
      <div
      v-show="department.expanded ? true : false"
      class="pt-2"
      :id="`department-${department.id}-expanded`">
        <ul class="space-y-3 p-3 pt-0">
          <Category
          v-for="category in categories"
          :key="category.id"
          v-bind:category="category"/>
        </ul>
        <footer class="flex flex-row items-center mt-3 border-primary-light p-3">
          <h3 class="flex-none text-xl font-serif font-semibold text-text">{{ department.name }} totals:</h3>
          <ul class="flex-auto flex flex-row items-center justify-end ml-6 space-x-6">
            <li
            v-for="(total, idx) in totals"
            class="flex-none"
            :class="total.style"
            :key="idx">
              <span class="block text-sm text-text-metadata">{{ total.name }}:</span>
              <span class="totals-figure block text-xl font-mono font-semibold text-text">{{ penceToPounds(total.value) }}</span>
            </li>
          </ul>
          <div
          class="flex-none flex items-center justify-center ml-6 rounded-full h-10 w-10 border border-primary hover:border-primary-dark text-primary hover:text-primary-dark transform transition-colors rotate-180 cursor-pointer"
          @click="toggleDetails">
            <TriangleDownIcon class="h-4 w-4 transition-colors fill-current"/>
          </div>
        </footer>
      </div>
    </li>
</template>

<script>
import Category from '@/components/category.vue'
import Button from '@/components/button.vue'
import TriangleDownIcon from '@/components/svg/iconTriangleDown.vue'
import { penceToPounds } from '@/components/js/utils.js'
import { mapState } from 'vuex'

export default {
  name: 'Department',
  components: {
    Category,
    Button,
    TriangleDownIcon
  },
  computed: {
    ...mapState({
      project: state => state.projects.project,
      categoryTotals: state => state.totals.categoryTotals
    }),
    categories: function () {
      /**
       * categoryUsed
       * returns True if the totals for a given category !== 0
       *
       */
      function categoryUsed (cat, ctx) {
        if (cat.id in ctx.categoryTotals) {
          const totals = ctx.categoryTotals[cat.id]
          let sum = 0
          for (const key in totals) {
            sum += totals[key]
          }
          if (sum !== 0) {
            return true
          }
        }
        return false
      }

      let cats = this.$store.state.categories.filter(
        cat => cat.department.id === this.$props.department.id
      )

      if (this.project.rec_mode) {
        cats = cats.filter(cat => {
          if (!this.department.rec_only) {
            return categoryUsed(cat, this)
          }
          return true
        })
      }
      return cats
    },
    totals: function () {
      if (this.$store.getters.departmentTotals(this.$props.department.id)) {
        return this.$store.getters.departmentTotals(this.$props.department.id).filter((total) => {
          if (!this.project.rec_mode && total.rec_only) {
            return null
          }
          return total
        })
      }
      return null
    },
    departmentVisible: function () {
      if (this.department.rec_only && this.project.rec_mode) { return true }
      if (this.categories.length >= 1) { return true }
      return false
    }
  },
  methods: {
    penceToPounds,
    toggleCategories: function (e) {
      e.preventDefault()
      // if any cats are expanded then collapse all, otherwise expand all
      const expand = !this.categories.some((item) => item.expanded)
      this.categories.forEach(cat => {
        this.$store.commit('setCategoryExpanded', {
          categoryId: cat.id,
          expanded: expand
        })
      })
    },
    toggleDetails: function (e) {
      e.preventDefault()
      this.$store.commit('setDepartmentExpanded', {
        departmentId: this.department.id,
        expanded: !this.department.expanded
      })
    }
  },
  props: {
    department: Object
  }
}
</script>
