// number properties
export const DEFAULT_LOCALE = 'en-GB'
export const DECIMAL_PLACES = 2
export const VAT_RATE = 20

// number input properties
export const DEFAULT_MAX_VALUE = 9999
export const DEFAULT_MIN_VALUE = 0
export const DEFAULT_STEP = (10 ** -(DECIMAL_PLACES))

// select box properties
export const DEFAULT_SELECT_STR = '---------'

// text properties
export const DEFAULT_STR = ''

// text input properties
export const DEFAULT_MAXLENGTH_VALUE = 140
export const DEFAULT_MINLENGTH_VALUE = 1

// Constants from spreadsheet
export const HOURS_IN_WORKING_DAY = 10

export const API_VERSION_PREFIX = 'api/v1/'
export const APP_VERSION = (process.env.NODE_ENV === 'production') ? process.env.VUE_APP_VERSION : '(Development mode)'
export const SITE_TITLE = 'Production Budgets'
export const SITE_URL = (process.env.NODE_ENV === 'production') ? `https://${process.env.VUE_APP_SITE_URL}/` : 'http://localhost:8010/'

// status codes
export const HTTP_200_OK = 200
export const HTTP_201_CREATED = 201
export const HTTP_204_NO_CONTENT = 204
export const HTTP_400_BAD_REQUEST = 400
export const HTTP_401_FORBIDDEN = 401
export const HTTP_404_NOT_FOUND = 404

// Constants dependent on backend db structure
export const PROJECT_PATCH_FIELDS = [
  'client_budget_pence',
  'client_discount_pc',
  'deliverables_summary',
  'description',
  'estimated_margin_pence',
  'estimated_margin_pc',
  'is_active',
  'point_of_contact',
  'production_fee_pc',
  'title',
  'rec_mode'
]

export const ONBLUR_TIMEOUT_MS = 200

export const maxProjectsMessage = 'Woah there! You have exceeded the limit ' +
  'of projects, if you wish to discuss purchasing unlimited access to ' +
  'ProdBud for your business please contact us!'
