<template>
  <main class="project-detail">
    <Page>
      <div v-if="!this.$store.state.loading">
        <ProjectHeader v-bind:project="project"/>
        <ul
        v-if="departments.length >= 1"
        class="space-y-6">
          <Department
          v-for="department in departments"
          :key="department.id"
          v-bind:department="department"/>
        </ul>
        <ProjectTotals :projectId="project.id"/>
      </div>
      <Spinner v-else/>
    </Page>
  </main>
</template>

<script>
import Department from '@/components/department.vue'
import Page from '@/components/page.vue'
import ProjectHeader from '@/components/projectHeader.vue'
import ProjectTotals from '@/components/projectTotals.vue'
import Spinner from '@/components/spinner.vue'
import { mapState } from 'vuex'

export default {
  name: 'ProjectDetail',
  components: {
    Department,
    Page,
    ProjectHeader,
    ProjectTotals,
    Spinner
  },
  computed: {
    ...mapState({
      project: state => state.projects.project
    }),
    departments: function () {
      return this.$store.state.departments
    }
  },
  created () {
    this.$store.dispatch('getProject', this.$route.params.id).then(() => {
      document.title = `${this.project.title} - Production Budget`
      this.$store.commit('setLoading', true)
      this.$store.dispatch('getOrganisation')
      this.$store.dispatch('getUnits')
      this.$store.dispatch('getDepartments')
      this.$store.dispatch('getCategories')
      this.$store.dispatch('getProjectLineItems', this.project)
    }).catch(() => {
      this.$router.push({ path: '/' })
    })
  }
}
</script>
