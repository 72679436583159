import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import ProjectDetail from '../views/ProjectDetail.vue'
import { SITE_TITLE } from '@/components/js/constants.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: SITE_TITLE
    },
    component: () => import('../views/ProjectList.vue')
  },
  {
    path: '/component_reference',
    name: 'Component Ref',
    component: () => import('../views/ComponentRef.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue')
  },
  {
    path: '/password-reset',
    name: 'Password Reset',
    component: () => import('../views/PasswordReset.vue')
  },
  {
    path: '/account-settings',
    name: 'Account Settings',
    component: () => import('../views/AccountSettings.vue')
  },
  {
    path: '/create-project',
    name: 'Project Create',
    component: () => import('../views/ProjectCreate.vue')
  },
  {
    path: '/clone-project/:id',
    name: 'Project Clone',
    component: () => import('../views/ProjectClone.vue')
  },
  {
    path: '/pages/:slug',
    name: 'Page',
    component: () => import('../views/PageDetail.vue')
  },
  {
    path: '/project/:id',
    name: 'Project Detail',
    component: ProjectDetail
  },
  {
    path: '/projects',
    name: 'Project List',
    meta: {
      title: 'Production Budget'
    },
    component: () => import('../views/ProjectList.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = 'Production Budget'
  }
})
router.beforeEach(async (to, from, next) => {
  if (!store.state.user?.user?.isAuthenticated) {
    await store.dispatch('getUser')
  }

  const isAuthenticated = store.state.user.user.isAuthenticated
  const authlessRoutes = ['Login', 'Password Reset']

  if (!isAuthenticated && !authlessRoutes.includes(to.name)) {
    next({ name: 'Login' })
  } else {
    next()
  }
})

export default router
