<template>
  <svg
  class="triangle-down-icon"
  fill="none"
  height="9"
  viewBox="0 0 12 9"
  width="12"
  xmlns="http://www.w3.org/2000/svg">
      <path d="M5.99902 9L11.999 0L-0.000977516 1.43099e-07L5.99902 9Z"/>
  </svg>
</template>

<script>
export default {
  name: 'TriangleDownIcon'
}
</script>
