import { createStore } from 'vuex'

import * as utils from '@/components/js/utils'
import alertsModule from './modules/alerts'
import modalModule from './modules/modal'
import projectLineItemModule from './modules/projectLineItem'
import projectModule from './modules/project'
import totalsModule from './modules/totals'
import userModule from './modules/user'

export default createStore({
  state: {
    loading: false,
    formValidationErrors: [],
    departments: [],
    categories: [],
    clients: [],
    lineItems: [],
    units: [],
    menuVisible: false,
    pages: [],
    stripe_customer_portal_url: null,
    stripe_purchase_subscription_url: null
  },
  getters: {
    projectDetailExpanded: (state) => {
      // returns true if any department or category
      // item has been expanded on project detail view
      const expanded = (item) => item.expanded
      if (state.departments.some(expanded)) {
        return true
      }
      if (state.categories.some(expanded)) {
        return true
      }
      return false
    }
  },
  mutations: {
    'setLoading' (state, loading) {
      state.loading = loading
    },
    setStripeCustomerPortalUrl (state, url) {
      state.stripe_customer_portal_url = url
    },
    StripePurchaseSubscriptionUrl (state, url) {
      state.stripe_purchase_subscription_url = url
    },
    'setCategories' (state, categories) {
      // set expanded, which defines if the category is expanded in project detail view
      state.categories = categories.map(cat => {
        cat.expanded = false
        return cat
      })
    },
    'setClients' (state, clients) {
      state.clients = clients
    },
    'setLineItems' (state, lineItems) {
      state.lineItems = lineItems
    },
    'setUnits' (state, units) {
      state.units = units
    },
    'setDepartments' (state, departments) {
      // set expanded, which defines if the department is expanded in project detail view
      state.departments = departments.map(dep => {
        dep.expanded = false
        return dep
      })
    },
    'setSidebarVisibility' (state, b) {
      state.sidebarVisible = b
    },
    'setFormValidationErrors' (state, errors) {
      state.formValidationErrors = errors
    },
    'addFormValidationError' (state, error) {
      state.formValidationErrors = [...state.formValidationErrors, error]
    },
    'toggleMenuVisibility' (state) {
      state.menuVisible = !state.menuVisible
    },
    /**
     * @param {Object} state - the VueX state
     * @param {Object} payload - Object containing categoryId & expanded Boolean
     */
    'setCategoryExpanded' (state, payload) {
      state.categories = state.categories.map(cat => {
        if (cat.id === payload.categoryId) {
          cat.expanded = payload.expanded
        }
        return cat
      })
    },
    /**
     * @param {Object} state
     * @param {Object} payload - contains departmentId & expanded Boolean
     */
    'setDepartmentExpanded' (state, payload) {
      state.departments = state.departments.map(dep => {
        if (dep.id === payload.departmentId) {
          dep.expanded = payload.expanded
        }
        return dep
      })
    },
    'setPages' (state, pages) {
      state.pages = pages
    }
  },
  actions: {
    /**
     * expandCollapseAllDepartmentsAndCategories
     * @param {Object} param0
     * @param {Boolean} expanded - true for expand, false for collapse
     */
    'expandCollapseAllDepartmentsAndCategories' ({ state, commit }, expanded) {
      state.departments.forEach(dep => {
        commit('setDepartmentExpanded', { departmentId: dep.id, expanded: expanded })
      })
      state.categories.forEach(cat => {
        commit('setCategoryExpanded', { categoryId: cat.id, expanded: expanded })
      })
    },
    'getStripeUrls' ({ commit }) {
      utils.apiRequest.get('stripe_urls/').then(response => {
        commit('setStripeCustomerPortalUrl', response.data.customer_portal_url)
        commit('StripePurchaseSubscriptionUrl', response.data.purchase_subscription_url)
      }).catch(error => {
        console.error(error)
      })
    },
    'getUnits' ({ commit }) {
      utils.apiRequest.get('unit/').then(response => {
        commit('setUnits', response.data)
      }).catch(error => {
        console.log(error)
      })
    },
    'getCategories' ({ commit }, queryString = null) {
      utils.getRequest('category', queryString, function (response) {
        commit('setCategories', response.data)
      })
    },
    'getClients' ({ commit }, queryString = null) {
      utils.getRequest('client', queryString, function (response) {
        commit('setClients', response.data)
      })
    },
    'getDepartments' ({ commit }) {
      utils.apiRequest.get('department/').then(response => {
        commit('setDepartments', response.data)
      }).catch(error => {
        console.error(error)
      })
    },
    'getLineItems' ({ commit }, queryString = null) {
      utils.getRequest('lineitem', queryString, function (response) {
        commit('setLineItems', response.data)
      })
    },
    'patchRec' ({ state, commit, dispatch }, payload) {
      if (!payload.id) {
        // add new rec
        utils.apiRequest.post('rec/', payload.rec).then((res) => {
          // just update the single projectlineitem with new rec
          const pli = state.projectLineItems.projectLineItems.filter(p => p.id === payload.rec.project_line_item)[0]
          pli.rec = res.data
          commit('setProjectLineItem', pli)
          dispatch('calculateAllTotals')
        }).catch(err => {
          commit('addAlert', {
            status: 'error',
            message: 'Could not create rec info, please try again.',
            timer: 3000
          })
          console.log(err)
        })
      } else {
        // update rec
        utils.apiRequest.put(`rec/${payload.id}/`, payload.rec).then((res) => {
          const pli = state.projectLineItems.projectLineItems.filter(p => p.id === payload.rec.project_line_item)[0]
          pli.rec = res.data
          commit('setProjectLineItem', pli)
          dispatch('calculateAllTotals')
        }).catch(err => {
          commit('addAlert', {
            status: 'error',
            message: 'Could not update rec info, please try again.',
            timer: 3000
          })
          console.log(err)
        })
      }
    },
    'patchArchiveProjects' ({ commit, dispatch }, payload) {
      return new Promise((resolve) => {
        utils.apiRequest.put('archive_projects/', payload).then((res) => {
          dispatch('getProjects').then(() => {
            commit('addAlert', {
              status: 'success',
              message: 'Selected projects have been archived.',
              timer: 3000
            })
            resolve()
          })
        })
      })
    },
    'toggleSidebarVisibility' ({ state, commit }) {
      commit('setSidebarVisibility', !state.sidebarVisible)
    },
    /*
    Gets `Page` entries from the backend api and commits to store
    */
    'getPages' ({ commit }) {
      utils.apiRequest.get('page/').then(response => {
        commit('setPages', response.data)
      }).catch(error => {
        console.log(error)
      })
    }
  },
  modules: {
    alertsModule,
    modalModule,
    projectLineItems: projectLineItemModule,
    projects: projectModule,
    totals: totalsModule,
    user: userModule
  }
})
