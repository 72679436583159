<template>
  <input
    id="project-line-item-input"
    class="w-full border-none focus:outline-focus shadow-none m-0"
    :class="createClass()"
    :type="type"
    :disabled="this.$props.disabled || false"
    :max="MAX_VALUE"
    :maxlength="MAXLENGTH_VALUE"
    :min="MIN_VALUE"
    :minlength="MINLENGTH_VALUE"
    :placeholder="placeholder"
    :step="step"
    :value="formattedValue"
    @blur="handleBlur"
    @input="handleInput"
  />
</template>

<script>
import * as constants from '@/components/js/constants.js'
import { poundsToPence, penceToPounds } from '@/components/js/utils.js'
import { mapState } from 'vuex'

export default {
  name: 'ProjectLineItemInput',
  computed: {
    ...mapState({
      project: state => state.projects.project
    })
  },
  data () {
    return {
      // Applicable to a number input
      MAX_VALUE: constants.DEFAULT_MAX_VALUE,
      MIN_VALUE: constants.DEFAULT_MIN_VALUE,
      // Applicable to a text input
      MAXLENGTH_VALUE: constants.DEFAULT_MAXLENGTH_VALUE,
      MINLENGTH_VALUE: constants.DEFAULT_MINLENGTH_VALUE,
      // If this is a money input, placeholder should be £ symbol, otherwise blank
      placeholder: this.$props.inputType === 'money' ? '£' : '',
      // If this is a money input, step should be a decimal, otherwise whole integers
      step: this.$props.inputType === 'money' ? constants.DEFAULT_STEP : 1,
      type: this.$props.inputType === 'number' || this.$props.inputType === '100x' ? 'number' : 'text',
      // Render money formatted version of the current modelValue in pounds
      formattedValue: this.formatValue()
    }
  },
  methods: {
    penceToPounds: penceToPounds,
    poundsToPence: poundsToPence,
    createClass () {
      let cls = this.$props.class
      if (!this.$props.noBackgroundColour) {
        if (this.project.rec_mode || !this.project.is_active) {
          cls = cls.concat(' bg-primary-light')
        }
      }
      return cls
    },
    /**
     * onInput: emit modelvalue update with pence conversion of formattedValue
     */
    handleInput (e) {
      this.formattedValue = e.target.value
      if (this.$props.inputType === 'money') {
        this.$emit('update:modelValue', poundsToPence(this.formattedValue))
      } else if (this.$props.inputType === '100x') {
        if (this.formattedValue === '' || this.formatedValue === null) {
          // this ensures we treat null quantity as 1
          this.$emit('update:modelValue', null)
        } else {
          this.$emit('update:modelValue', Number(this.formattedValue * 100).toFixed(0))
        }
      } else {
        this.$emit('update:modelValue', e.target.value)
      }
    },
    handleBlur () {
      // onBlur: format with penceToPounds
      if (this.$props.inputType === 'money') {
        this.formattedValue = penceToPounds(poundsToPence(this.formattedValue), true)
      }
    },
    formatValue () {
      switch (this.$props.inputType) {
        case 'money':
          return penceToPounds(this.$props.modelValue, true)
        case '100x':
          return this.$props.modelValue / 100 || null
        default:
          return this.$props.modelValue
      }
    }
  },
  props: ['modelValue', 'inputType', 'disabled', 'class', 'noBackgroundColour']
}
</script>
