<template>
  <div
    v-if="this.$store.state.alertsModule.alerts"
    id="alerts"
    class="fixed left-1/2 transform -translate-x-1/2 top-1 z-20"
  >
    <transition-group appear tag="div"
      mode="in-out"
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-for="alert in this.$store.state.alertsModule.alerts" :key="alert.id">
        <Alert :alert="alert"/>
      </div>
    </transition-group>
  </div>
</template>
<script>
import Alert from '@/components/alert.vue'
export default {
  name: 'Alerts',
  components: {
    Alert
  }
}
</script>
