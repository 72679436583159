import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

const app = createApp(App)

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://61a91ede76404571922522eaf9e420a7@o240504.ingest.sentry.io/5981011',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'prod-bud.omni-digital.co.uk', /^\//]
      })
    ],
    tracesSampleRate: 1.0
  })
} else {
  app.config.devtools = true
}

app.use(store).use(router).mount('#app')
