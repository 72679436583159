<template>
  <header
  class="bg-white mt-0 w-full border-b border-primary-light"
  aria-label="Global header">
    <div class="2xl:container mx-auto px-3 py-3 flex flex-row justify-items-center justify-between">
      <div class="w-full flex flex-row items-center justify-between">
        <a
        class="flex-none"
        href="/"
        aria-label="Return to home page">
          <span class="inline-block font-semibold text-text-heading font-serif text-2xl"><span class="text-primary-dark">Prod</span>uction <span class="text-primary-dark">Bud</span>gets.</span>
          <span class="inline-block ml-2 text-sm text-text">{{ appVersion }}</span>
        </a>
        <div class="flex-none relative z-auto ml-2">
          <button
          class="flex flex-row justify-center items-center w-full"
          aria-expanded="true"
          aria-haspopup="true"
          buttontype="neutral-border"
          id="header-menu-button"
          v-on:blur="handleBlur()"
          v-on:click="this.$store.commit('toggleMenuVisibility')">
            <NavigationIcon class="text-primary-dark transition-colors stroke-current hover:text-primary-darkest"/>
          </button>
          <nav
          class="origin-top-right absolute z-10 right-0 mt-4 w-56 rounded border border-primary-light bg-white focus:outline-none"
          aria-labelledby="header-menu-button"
          aria-orientation="vertical"
          id="header-menu-list"
          role="menu"
          tabindex="-1"
          v-bind:class="{ hidden : !this.$store.state.menuVisible }">
            <ul class="w-full">
              <li
              v-for="item in menuItems"
              class="w-full"
              :key="item.name"
              role="none">
                <span
                @click="navigate(item)"
                v-bind:class="this.$route.path === item.path ? 'menu-list-item menu-list-item--active' : 'menu-list-item menu-list-item--not-active'">
                  {{ item.name }}
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import NavigationIcon from '@/components/svg/iconNavigation.vue'
import * as constants from '@/components/js/constants.js'
import * as utils from '@/components/js/utils'
import { mapState } from 'vuex'

export default {
  name: 'Header',
  components: {
    NavigationIcon
  },
  created () {
    this.$store.dispatch('getPages')
  },
  computed: {
    ...mapState({
      project: state => state.projects.project,
      projectTotals: state => state.totals.projectTotals,
      user: state => state.user.user
    }),
    appVersion: function () {
      return constants.APP_VERSION
    },
    headerTotals: function () {
      // show totals if user is on project detail page
      if (this.$route.name === 'Project Detail') {
        return {
          billToClient: utils.penceToPounds(this.projectTotals.totalBillToClient),
          clientBudget: utils.penceToPounds(this.project.client_budget_pence),
          leftToPay: utils.penceToPounds(this.project.client_budget_pence - this.projectTotals.totalRecInvoiced)
        }
      } else {
        return null
      }
    },
    /*
    Sets the items to be listed in the menu
    */
    menuItems: function () {
      var items = [
        { path: '/', name: 'Home', internal: true }
      ]
      // Include any pages from the store
      const pages = this.$store.state.pages
      for (let i = 0; i < pages.length; i++) {
        const page = pages[i]
        items.push({ path: `/pages/${page.slug}`, name: page.title, internal: true })
      }
      // Customise login or logout depending on whether the user is authenticated
      if (this.user.isAuthenticated) {
        items.push({
          path: '/account-settings',
          name: 'Account Settings',
          internal: true
        })
        items.push(
          { path: 'https://knowledge.prodbud.app/', name: 'Help', internal: false, newTab: true }
        )
        items.push(
          {
            path: '/logout',
            name: `Log out (${this.user.username})`,
            internal: true
          }
        )
      } else {
        items.push(
          { path: 'https://knowledge.prodbud.app/', name: 'Help', internal: false, newTab: true },
          { path: '/login', name: 'Log in', internal: true }
        )
      }
      return items
    }
  },
  methods: {
    /*
    Sets `menuVisible` to `false` after a timeout to handle button focus out events
    Timeout is required to ensure menu clicks can be actioned before parent blur hides the menu
    */
    handleBlur: function () {
      setTimeout(() => { this.$store.state.menuVisible = false }, constants.ONBLUR_TIMEOUT_MS)
    },
    /*
    Navigate to clicked link and toggle menu visibility
    */
    navigate: function (item) {
      if (item.internal) {
        this.$router.push(item.path)
      } else {
        if (item.newTab) {
          window.open(item.path, '_blank')
        } else {
          window.location.replace(item.path)
        }
      }
      this.$store.commit('toggleMenuVisibility')
    },
    penceToPounds: utils.penceToPounds

  }
}
</script>
