<template>
  <svg
  class="global-navigation-icon"
  fill="none"
  height="24"
  viewBox="0 0 34 24"
  width="34"
  xmlns="http://www.w3.org/2000/svg">
    <path
    d="M32 2H2"
    stroke-width="3"
    stroke-linecap="round"/>
    <path
    d="M32 12H2"
    stroke-width="3"
    stroke-linecap="round"/>
    <path
    d="M32 22H8"
    stroke-width="3"
    stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'NavigationIcon'
}
</script>
