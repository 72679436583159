<template>
  <div
  v-if="modal.visible"
  class="fixed w-screen h-screen flex flex-col z-50 bg-interface-modal">
    <div class="m-auto min-w-max w-96 bg-white rounded-lg bxsh border border-primary p-6">
      <header class="flex flex-row items-center justify-between border-b-2 border-primary-light pb-5">
        <h2 class="my-0 text-xl">{{ modal.title }}</h2>
        <button
        class="ml-6 text-primary hover:text-primary-dark"
        @click="close">
          <Icon
          class="h-8 w-8 transition-colors fill-current"
          name="close"/>
        </button>
      </header>
      <!-- Notes Modal body -->
      <section
      v-if="modal.type === 'notes'"
      class="flex flex-col items-end pt-6 text-text-metadata">
        <textarea
        v-if="modal.editable"
        class="text-area-simplified rounded border-primary-light p-3 text-text focus:border-primary shadow-remove w-full"
        id="bodyText"
        name="bodyText"
        rows="10"
        v-model="modal.bodyText"
        @input="enforceMaxLength"></textarea>
        <p v-if="modal.editable">{{ this.maxLength - (modal.bodyText?.length | 0) }} charaters remaining</p>
        <Button
        v-if="modal.editable"
        class="mt-3 mr-0 mb-0 ml-0 transition-colors hover:bg-primary-dark"
        buttontype="background"
        @click.prevent="onClick">Save</Button>
        <p v-if="!modal.editable && modal.bodyText">{{ modal.bodyText }}</p>
        <p
        v-if="!modal.editable && !modal.bodyText"
        class="text-neutral-400">No notes.</p>
      </section>
      <!-- End Notes Modal body -->
      <!-- Confirmation Modal body -->
      <section
      v-else-if="modal.type === 'confirmation'"
      class="flex flex-row justify-end">
        <Button
        class="mt-3 mr-0 mb-0 hover:bg-primary-dark transition-colors"
        buttontype="background"
        @click.prevent="close">No</Button>
        <Button
        class="mt-3 mr-0 mb-0 ml-3 transition-colors hover:text-primary-dark hover:border-primary-dark"
        buttontype="border"
        @click.prevent="onClick">Yes</Button>
      </section>
      <!-- End Confirmation Modal body -->
    </div>
  </div>
</template>

<script>
import Button from '@/components/button.vue'
import Icon from '@/components/icon.vue'
import { mapState } from 'vuex'

export default {
  name: 'Modal',
  components: { Icon, Button },
  computed: mapState({ modal: state => state.modalModule.modal }),
  data () {
    return {
      maxLength: 100
    }
  },
  methods: {
    close () {
      this.modal.visible = false
    },
    open () {
      this.modal.visible = true
    },
    onClick () {
      this.modal.onClick()
      this.modal.visible = false
    },
    enforceMaxLength () {
      if (this.modal.bodyText?.length > this.maxLength) {
        this.modal.bodyText = this.modal.bodyText.slice(0, this.maxLength)
      }
    }
  }
}
</script>
