import * as utils from '@/components/js/utils'
import * as constants from '@/components/js/constants'

const projectLineItemModule = {
  state: () => ({
    projectLineItems: []
  }),
  mutations: {
    setProjectLineItems (state, projectLineItems) {
      state.projectLineItems = [...projectLineItems]
    },
    setProjectLineItem (state, projectLineItem) {
      const plis = state.projectLineItems
      plis.map((pli) => {
        if (pli.id === projectLineItem.id) {
          pli = projectLineItem
        }
        return pli
      })
      state.projectLineItems = plis
    },
    addProjectLineItem (state, pli) {
      state.projectLineItems = [...state.projectLineItems, pli]
    }
  },
  actions: {
    createProjectLineItem ({ state, commit, dispatch }, data) {
      utils.apiRequest.post('projectlineitem/', data).then(response => {
        if (response.status === constants.HTTP_201_CREATED) {
          commit('addProjectLineItem', response.data)
          dispatch('calculateAllTotals')
          commit('addAlert', {
            status: 'success',
            message: 'New project line item created successfully.',
            timer: 3000
          })
        } else {
          commit('addAlert', {
            status: 'error',
            message: 'Project line item create failed, please try again.',
            timer: 3000
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    deleteProjectLineItem ({ state, commit, dispatch }, id) {
      utils.apiRequest.delete(`projectlineitem/${id}/`).then(response => {
        if (response.status === constants.HTTP_204_NO_CONTENT) {
          const plis = state.projectLineItems.filter(pli => pli.id !== id)
          commit('setProjectLineItems', plis)
          dispatch('calculateAllTotals')
          commit('addAlert', {
            status: 'success',
            message: `Project line item id ${id} deleted successfully.`,
            timer: 3000
          })
        } else {
          commit('addAlert', {
            status: 'error',
            message: 'Project line item delete failed, please try again.',
            timer: 3000
          })
        }
      }).catch(error => {
        console.log(error)
      })
    },
    getProjectLineItems ({ state, commit, dispatch }, project) {
      utils.getRequest(
        'projectlineitem',
        `project_id=${project.id}${project.rec_mode ? '&rec_mode=1' : ''}`,
        response => {
          commit('setProjectLineItems', response.data)
          dispatch('calculateAllTotals').then(() => {
            commit('setLoading', false)
          })
        }
      )
    },
    patchProjectLineItem ({ commit, getters, dispatch }, pli) {
      const formatted = getters.projectLineItemPatchFormat(pli)
      if (formatted) {
        utils.apiRequest.patch(`projectlineitem/${pli.id}/`, formatted).then((res) => {
          commit('setProjectLineItem', res.data)

          var category
          if (pli.line_item !== null) {
            category = pli.line_item.category
          } else {
            category = pli.category
          }

          dispatch('calculateCategoryTotals', category).then(() => {
            dispatch('calculateAllDepartmentTotals').then(() => {
              dispatch('calculateProjectTotals')
            })
          })
        }).catch(error => {
          commit('addAlert', {
            status: 'error',
            message: 'Could not update record, please try again.',
            timer: 3000
          })
          console.log(error)
        })
      } else {
        commit('addAlert', {
          status: 'error',
          message: 'Could not update record.',
          timer: 3000
        })
        console.error(`Project line item with id ${pli.id} could not be found in the store.`)
      }
    }
  },
  getters: {
    projectLineItemPatchFormat: (state) => (pli) => {
      return {
        id: pli.id,
        external_cost_pence: pli.external_cost_pence,
        internal_cost_pence: pli.internal_cost_pence,
        internal: pli.internal,
        night_ot_hours: pli.night_ot_hours,
        prep_time_days: pli.prep_time_days,
        quantity: pli.quantity,
        std_ot_hours: pli.std_ot_hours,
        work_time_days: pli.work_time_days,
        // custom values if present
        name: pli.name,
        unit: pli.unit,
        notes: pli.notes
      }
    },
    projectLineItemsByDepartment: (state, getters, rootState) => {
      const resultMap = {}
      rootState.departments.forEach((dep) => {
        resultMap[dep.id] = {
          department: dep,
          projectLineItems: [],
          totals: rootState.totals.departmentTotals[dep.id]
        }
      })

      rootState.categories.forEach((cat) => {
        state.projectLineItems.forEach((pli) => {
          if (pli.category === cat.id) {
            resultMap[cat.department.id].projectLineItems.push(pli)
          }
        })
      })

      return resultMap
    }
  }
}

export default projectLineItemModule
