<template>
  <div class="relative z-auto">
    <button
    class="flex flex-row justify-center items-center text-white hover:text-primary-lightest"
    aria-expanded="true"
    aria-haspopup="true"
    id="options-menu-button"
    type="button"
    v-on:blur="handleBlur()"
    v-on:click="toggleMenuVisibility()">
      <SettingsIcon class="h-10 w-10 stroke-current transition-colors"/>
      <CaretDownIcon class="h-3 w-3 ml-1 stroke-current transition-colors"/>
    </button>
    <nav
    class="origin-top-right absolute z-10 right-0 mt-2 w-56 rounded border border-primary-light bg-white focus:outline-none"
    aria-labelledby="options-menu-button"
    aria-orientation="vertical"
    role="menu"
    tabindex="-1"
    v-bind:class="{ hidden : !menuHidden }">
      <ul class="w-full">
        <li
        v-for="item in dropdownItems"
        class="w-full"
        :key="item.displayStr"
        role="none">

          <router-link
            v-if="item.route && !item.disabled"
            class="menu-list-item menu-list-item--not-active"
            :id="`menu-item-${item.displayStr}`"
            :to="item.route"
            role="menuitem"
            tabindex="-1"
          >
            {{ item.displayStr }}
          </router-link>

          <span
            v-else-if="item.disabled"
            :class="`menu-list-item ${item.disabled ? 'text-gray-500' : ''}`"
            :id="`menu-item-${item.displayStr}`"
            tabindex="-1"
          >
            {{ item.displayStr }}
          </span>

          <a
            v-else
            class="menu-list-item menu-list-item--not-active"
            :id="`menu-item-${item.displayStr}`"
            href="#"
            role="menuitem"
            tabindex="-1"
            v-on:click="!item.disabled ? setReturnVal(item) : ()=>{} "
          >
            {{ item.displayStr }}
          </a>

        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { ONBLUR_TIMEOUT_MS } from '@/components/js/constants.js'
import CaretDownIcon from '@/components/svg/iconCaretDown.vue'
import SettingsIcon from '@/components/svg/iconSettings.vue'

export default {
  name: 'DropdownSettings',
  components: {
    CaretDownIcon,
    SettingsIcon
  },
  created () {
    this.setButtonStr(this.$props.dropdownItems[0].displayStr)
  },
  data () {
    return {
      buttonStr: '',
      menuHidden: false
    }
  },
  methods: {
    /*
    Sets `menuHidden` to `true` after a timeout to handle button focus out events
    Timeout is required to ensure menu clicks can be actioned before parent blur hides the menu
    */
    handleBlur: function () {
      setTimeout(() => { this.menuHidden = false }, ONBLUR_TIMEOUT_MS)
    },
    /*
    Toggles `menuHidden` for menu visibility
    */
    toggleMenuVisibility: function () {
      this.menuHidden = !this.menuHidden
    },
    /*
    Function sets button string depending whether `showButtonStr` prop is set
    */
    setButtonStr: function (value) {
      if (this.$props.dropdowntype === 'options') {
        this.buttonStr = 'Options'
      } else {
        this.buttonStr = value
      }
    },
    /*
    Function sets `returnVal` v-model argument with the `dropdownItem` selected and updates
    display strings
    */
    setReturnVal: function (menuItem) {
      this.$emit('update:modelValue', menuItem)
      // Update the dropdown menu string
      this.setButtonStr(menuItem.displayStr)
      // Update menu hidden as menu has been clicked
      this.toggleMenuVisibility()
    }
  },
  props: {
    dropdownItems: Object,
    dropdowntype: String
  }
}
</script>
