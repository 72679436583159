<template>
  <svg
  class="collapse-icon"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
    <path
    d="M10.5 13.5L3 21M10.5 13.5V19.1M10.5 13.5H4.9"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"></path>
    <path
    d="M13.5 10.5L21 3M13.5 10.5V4.84315M13.5 10.5H19.1568"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="1.5"></path>
  </svg>
</template>

<script>
export default {
  name: 'CollapseIcon'
}
</script>
