<template>
  <div class="flex min-h-screen">
    <Modal/>
    <RecModal/>
    <div class="w-full bg-primary-lightest">
      <Header/>
      <Alerts/>
      <router-view/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Alerts from '@/components/alerts.vue'
import Modal from '@/components/modal.vue'
import RecModal from '@/components/recModal.vue'

export default {
  name: 'App',
  components: { Header, Alerts, Modal, RecModal }
}
</script>
