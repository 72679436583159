<template>
  <div>
    <Label v-bind="{labelStr: label, required: required}"></Label>
    <input
    class="block w-full rounded"
    :class="this.$props.disabled ? 'bg-text-metadata ' + inputclass : inputclass"
    :disabled="this.$props.disabled"
    @blur="$emit('blur')"
    @focus="$emit('focus')"
    :placeholder="this.$props.placeholder"
    v-bind="{type: type, value: modelValue}"
    v-on:input="$emit('update:modelValue', $event.target.value)"/>
    <span
    v-if="errors && errors[field]"
    class="text-error-500">{{ errors[field][0] }}</span>
  </div>
</template>

<script>
import Label from '@/components/label.vue'

export default {
  name: 'Input',
  components: {
    Label
  },
  props: {
    errors: Object,
    field: String,
    label: String,
    modelValue: String || Number,
    type: String,
    required: Boolean,
    inputclass: String,
    disabled: Boolean,
    placeholder: String
  }
}
</script>
