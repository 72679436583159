import * as utils from '@/components/js/utils.js'

const totalsModule = {
  state: () => ({
    categoryTotals: {},
    departmentTotals: {},
    projectTotals: {}
  }),
  mutations: {
    setCategoryTotals (state, payload) {
      state.categoryTotals[payload.id] = payload.totals
    },
    setDepartmentTotals (state, payload) {
      state.departmentTotals[payload.id] = payload.totals
    },
    setProjectTotals (state, payload) {
      state.projectTotals = payload.totals
    }
  },
  actions: {
    calculateAllTotals ({ dispatch }) {
      dispatch('calculateAllCategoryTotals').then(() => {
        dispatch('calculateAllDepartmentTotals').then(() => {
          dispatch('calculateProjectTotals')
        })
      })
    },
    calculateAllCategoryTotals ({ state, commit, rootState }) {
      rootState.categories.forEach(cat => {
        const totals = {
          totalBreakEvenCost: 0,
          totalBillable: 0,
          margin: 0,
          totalInternalBECost: 0,
          totalExternalBECost: 0,
          totalInternalCosts: 0,
          totalRecInvoiced: 0
        }
        utils.filterProjectLineItemsByCategory(
          rootState.projectLineItems.projectLineItems, cat.id
        ).forEach(pli => {
          totals.totalRecInvoiced += Number(utils.recInvoiced(pli)) || 0
          totals.totalBreakEvenCost += utils.breakEvenCost(pli) || 0
          totals.totalBillable += utils.totalBillable(pli) || 0
          totals.margin += (totals.totalBillable - totals.totalBreakEvenCost) || 0
          totals.totalInternalBECost += utils.internalBECost(pli) || 0
          totals.totalExternalBECost += utils.externalBECost(pli) || 0
          totals.totalInternalCosts += pli.internal_cost_pence || 0
        })
        commit('setCategoryTotals', { id: cat.id, totals: totals })
      })
    },
    calculateCategoryTotals ({ state, commit, rootState }, id) {
      const totals = {
        totalBreakEvenCost: 0,
        totalBillable: 0,
        margin: 0,
        totalInternalBECost: 0,
        totalExternalBECost: 0
      }

      if (id) {
        utils.filterProjectLineItemsByCategory(
          rootState.projectLineItems.projectLineItems, id
        ).forEach((pli) => {
          totals.totalBreakEvenCost += utils.breakEvenCost(pli)
          totals.totalBillable += utils.totalBillable(pli)
          totals.margin += totals.totalBillable - totals.totalBreakEvenCost
          totals.totalInternalBECost += utils.internalBECost(pli)
          totals.totalExternalBECost += utils.externalBECost(pli)
        })
        commit('setCategoryTotals', { id: id, totals: totals })
      }
    },
    calculateAllDepartmentTotals ({ state, commit, rootState }) {
      rootState.departments.forEach(dep => {
        const categories = rootState.categories.filter(cat => cat.department.id === dep.id)
        const totals = {
          totalInternalBECost: 0,
          totalExternalBECost: 0,
          totalBreakEvenCost: 0,
          totalTotalBillable: 0,
          totalInternalCosts: 0,
          totalRecInvoiced: 0,
          allRecsInvoiced: false
        }
        categories.forEach(cat => {
          totals.totalInternalBECost += state.categoryTotals[cat.id].totalInternalBECost
          totals.totalExternalBECost += state.categoryTotals[cat.id].totalExternalBECost
          totals.totalBreakEvenCost += state.categoryTotals[cat.id].totalBreakEvenCost
          totals.totalTotalBillable += state.categoryTotals[cat.id].totalBillable
          totals.totalInternalCosts += state.categoryTotals[cat.id].totalInternalCosts
          totals.totalRecInvoiced += state.categoryTotals[cat.id].totalRecInvoiced
        })
        commit('setDepartmentTotals', { id: dep.id, totals: totals })
      })
    },
    calculateProjectTotals ({ state, commit, rootState }) {
      const totals = {}
      totals.totalInternalCosts = 0
      totals.totalExternalCosts = 0
      totals.totalBreakEvenCost = 0
      totals.totalBillToClient = 0
      totals.totalInternalBECost = 0
      totals.difference = 0
      totals.totalRecInvoiced = 0

      for (const key in state.departmentTotals) {
        totals.totalInternalCosts += state.departmentTotals[key].totalInternalCosts
        totals.totalExternalCosts += state.departmentTotals[key].totalExternalBECost
        totals.totalBreakEvenCost += state.departmentTotals[key].totalBreakEvenCost
        totals.totalInternalBECost += state.departmentTotals[key].totalInternalBECost
        totals.totalBillToClient += state.departmentTotals[key].totalTotalBillable
        totals.totalRecInvoiced += state.departmentTotals[key].totalRecInvoiced
      }

      totals.productionFee = Number.parseInt((rootState.projects.project.production_fee_pc / 100) * totals.totalExternalCosts)
      totals.totalBillToClient += totals.productionFee
      totals.totalClientDiscount = Number.parseInt(
        totals.totalBillToClient - (
          rootState.projects.project.client_discount_pc / 100
        ) * totals.totalBillToClient
      )

      const estimatedProfitValue = Number.parseInt((totals.totalBillToClient - totals.totalBreakEvenCost))
      var estimatedProfitPc = 0
      // Only calculate `estimatedProfitPc` if `estimatedProfitValue` is not 0
      if (estimatedProfitValue) {
        estimatedProfitPc = parseInt((estimatedProfitValue / totals.totalClientDiscount) * 100)
      }

      totals.estimatedProfit = {
        value: estimatedProfitValue,
        percentage: estimatedProfitPc
      }
      totals.difference = rootState.projects.project.client_budget_pence - totals.totalBillToClient

      commit('setProjectTotals', { totals: totals })
      // Also commit estimated profit values to the `project` store object
      commit('updateProject',
        {
          estimated_margin_pc: totals.estimatedProfit.percentage,
          estimated_margin_pence: totals.estimatedProfit.value
        }
      )
    }
  },
  getters: {
    departmentRecPaid: (state, getters, rootState) => (id) => {
      let isPaid = true
      rootState.categories.filter(c => c.department.id === id).forEach(c => {
        rootState.projectLineItems.projectLineItems.filter(p => p.category === c.id).forEach(p => {
          if (!p.rec || !p.rec.paid) {
            isPaid = false
          }
        })
      })
      return isPaid
    },
    departmentTotals: (state, getters) => (id) => {
      if (id in state.departmentTotals) {
        const dep = state.departmentTotals[id]
        const recStyle = getters.departmentRecPaid(id) ? 'text-green-300' : 'text-yellow-400'
        return [
          { name: 'Rec invoiced', value: dep.totalRecInvoiced, style: recStyle, rec_only: true },
          { name: 'Break even cost', value: dep.totalBreakEvenCost },
          { name: 'Total billable', value: dep.totalTotalBillable },
          { name: 'Margin', value: dep.totalTotalBillable - dep.totalBreakEvenCost }
        ]
      }
      return null
    },
    projectTotals: (state) => {
      return state.projectTotals
    },
    projectRecsPaid: (state, getters, rootState) => {
      let allPaid = true
      rootState.departments && rootState.departments.forEach((department) => {
        if (!getters.departmentRecPaid(department.id)) {
          allPaid = false
        }
      })
      return allPaid
    }
  }
}
export default totalsModule
